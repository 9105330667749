import Layout from '../../components/Navigation/layout'
import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../services/ApiService";
import {Link} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-light-svg-icons";
import * as style from './index.module.scss';
import {toast} from "react-toastify";
import Loading from "../../components/Loading/Loading";
import AccountBanner from "../../components/AccountBanner/AccountBanner";
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import {setBookmarkAmount} from "../../stores/bookmark/bookmarkSlice";

const mapState = ({member, auth}) => ({
    member,
    auth
})

const mapDispatch = {
    setBookmarkAmount,
}



const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

const FavPage: FC<PropsFromRedux> = ({member, auth, setBookmarkAmount}) => {
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
            setFavorites(res.data.Products)
        }).catch(handleApiError);
    }, [])


    function removeBookmark(id: number) {
        Client.Bookmark.remove(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt von der Merkliste entfernt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setFavorites(res.data.Products)
                setBookmarkAmount(res.data.Count)
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    return (
        <Layout pageTitle="Merkliste">
            <AccountBanner isVendor={false} title={"Merkliste"}/>
            <Container className={"pt-5"}>
                <Row>
                    {typeof favorites !== 'undefined' ?
                        <>
                            {favorites.length ?
                                <>
                                    {favorites.map((fav: any, index: number) => {
                                        return (
                                            <Col className={style.holder} lg={4} key={index}>
                                                <div
                                                      className={style.link}>
                                                    <div className={style.iconHolder}>
                                                        <Link to={`/${fav.CategoryURLTitle}/${fav.URLTitle}`}>
                                                        <img src={fav.PreviewImage} className={style.icon}/>
                                                        </Link>
                                                        {auth.Key ?
                                                            <>
                                                                <OverlayTrigger
                                                                    key={"top-favs"}
                                                                    placement={"top"}
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-top-favs`}>
                                                                            Von der Merkliste entfernen
                                                                        </Tooltip>
                                                                    }
                                                                >
                                    <span className={style.heartHolder} onClick={() => removeBookmark(fav.ID)}>
                                        <FontAwesomeIcon icon={faTimes} className={style.heart}/>
                                    </span>
                                                                </OverlayTrigger>

                                                            </> : null

                                                        }
                                                    </div>
                                                </div>

                                                <h5 className={style.category}>
                                                    {fav.CategoryTitle}
                                                </h5>
                                                <h3 className={style.name}>
                                                    {fav.Title}
                                                </h3>
                                                <h3 className={style.price}>
                                                    {fav.NicePrice}
                                                </h3>
                                                <p className={style.mwst}>
                                                    inkl. MwSt.
                                                </p>

                                            </Col>
                                        )
                                    })}
                                </> : <Loading type={"border"} message={"Favoriten werden geladen"}/>
                            }
                        </>
                        : <h2 className={style.noProd}>Es befinden sich derzeit keine Produkte in deiner Merkliste.</h2>
                    }
                </Row>
            </Container>
        </Layout>
    )
}

export default connector(FavPage);
