// extracted by mini-css-extract-plugin
export var category = "index-module--category--vq41G";
export var heading = "index-module--heading--DVZpe";
export var heart = "index-module--heart--1602W";
export var heartHolder = "index-module--heartHolder--uIad3";
export var holder = "index-module--holder--fqFK6";
export var hoverHeartClass = "index-module--hoverHeartClass--wcA1d";
export var icon = "index-module--icon--APlWg";
export var iconHolder = "index-module--iconHolder--pbEOf";
export var link = "index-module--link--40ZT3";
export var mwst = "index-module--mwst--c5Pt5";
export var name = "index-module--name--F4JaL";
export var noProd = "index-module--noProd--1KnSy";
export var price = "index-module--price--MMhCU";